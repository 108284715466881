import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3"

import LinkedInImage from "../../../static/assets/icons/linkedInWordmark.svg"
import InstaLogo from "../../../static/assets/icons/Instagram.svg"
import TwitterLogo from "../../../static/assets/icons/Twitter.svg"
import LinkedLogo from "../../../static/assets/icons/LinkedIn.svg"

const Button = styled.div`
    height: fit-content;
    width: fit-content;
    background: rgba(179, 179, 179, 0.25);
    border: 0.6px solid #000000;
    box-sizing: border-box;
    border-radius: 16px;
    margin: 0.25em;
    padding: 0px 8px;
    cursor: pointer;
    text-transform: uppercase;


    * {color: black;}


    :hover {
        background: rgba(125, 125, 125, 0.5);
        transform: scale(1.1);
        transition: all 0.3s;
    }

    :active,focus {
        transform: scale(1);
        border-color: white;

        * {color: white;}
    }

    &.linkedIn {
        width: 90px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        
        
        :active,focus {
            transform: scale(1.1);
            border-color: #000;
            filter: invert(100%);
            -webkit-filter: invert(100%);
        }
    }

    &.white {
        background: rgba(255, 255, 255, 0.25);
        border: 0.6px solid #fff;
        border-radius: 12px;

        * {color: white;}
        

        :hover {
            background: rgba(255, 255, 255, 0.5);
        }

        :active,focus {
            transform: scale(1.1);
            border-color: #000;
            
            * {color: #000;}
        }
    }

    &.black {
        background: rgba(0, 0, 0, 1);
        border: 0.6px solid #000;   
        padding: 1px 12px;

            &.submit {
                padding: 2px 22px;

            :disabled {
                    background: #AAA;
                    border: 0.6px solid #AAA;
                }
            }
    

            * {color: white;}

            :hover {
                background: rgba(50, 50, 50, 0.75);
                }

            :active,focus {
                transform: scale(1.1);
                background-color: white;
                border: 0.6px solid #fff;  

                * {color: #000;}}
            }

        @media (max-width: 768px) {
            margin: auto;
        }
    }

    &.footer {
        margin: 30px 30px 30px auto;
    }

    * {
        text-decoration: none;
        -webkit-user-select: none;  
        -moz-user-select: none;     
        -ms-user-select: none;     
        user-select: none;
    }
`

const HoverRef = styled.a`
  text-align: center;

  * {
    width: 50%;
  }
`

export const PageButton = props => {
  return (
    <Button className={`${props.styleName}`}>
      <Link to={props.linkRef} style={{ whiteSpace: "nowrap" }}>
        {props.children}
      </Link>
    </Button>
  )
}

export const ModalButton = props => {
  return (
    <ModalRoutingContext.Consumer>
      {({ closeTo }) => (
        <Button className={`${props.styleName}`}>
          <Link to={closeTo} style={{ whiteSpace: "nowrap" }}>
            {props.children}
          </Link>
        </Button>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export const PopButton = props => {
  return (
    <Button className="black">
      <Link
        to={props.linkRef}
        style={{ whiteSpace: "nowrap" }}
        state={{ modal: true, noScroll: true }}
      >
        {props.children}
      </Link>
    </Button>
  )
}

export const SubmitButton = props => {
  return (
    <Button
      as="button"
      type="submit"
      className="black submit"
      onClick={props.click}
      disabled={props.disabled}
    >
      <div>{props.children}</div>
    </Button>
  )
}

//These following are the icons for social following section and menu.

export const LinkedInButton = props => {
  return (
    <>
      <a href={props.linkRef} target="_blank" rel="noreferrer">
        <Button className="linkedIn">
          <LinkedInImage />
        </Button>
      </a>
    </>
  )
}

export const LinkedInIcon = () => {
  return (
    <HoverRef
      href="https://www.linkedin.com/company/tuva-partners/"
      target="_blank"
      rel="noreferrer"
    >
      <LinkedLogo />
    </HoverRef>
  )
}

export const TwitterIcon = () => {
  return (
    <HoverRef
      href="https://twitter.com/TuvaPartners"
      target="_blank"
      rel="noreferrer"
    >
      <TwitterLogo />
    </HoverRef>
  )
}

export const InstagramIcon = () => {
  return (
    <HoverRef
      href="https://www.instagram.com/tuvapartners/"
      target="_blank"
      rel="noreferrer"
    >
      <InstaLogo />
    </HoverRef>
  )
}
