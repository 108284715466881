import React from 'react'
import { graphql } from "gatsby"
import { PageButton } from "../components/UI/Buttons"

const Job = ({ data }, props) => {
    const { role, salary, requirements } = data.markdownRemark.frontmatter;
    const html = data.markdownRemark.html;

    const salVisible = salary.visible; //Check if the requirements should be visible.
    const reqVisible = requirements.visible; //Check if the requirements should be visible.


    return (
        <div style={{margin: '2em', height: 'fit-content'}}>
            <h4>{role}</h4>
            <div className="requirements">
                <h3 style={salVisible ? {display: 'inherit'} : {display: 'none'}}>
                {salary.amount}
                </h3>
                <h3 style={reqVisible ? {display: 'inherit'} : {display: 'none'}}>
                {requirements.details}
                </h3>
            </div>
            <p dangerouslySetInnerHTML={{ __html: html }}/>
            <div style={{display: 'flex'}}>
            <PageButton styleName="footer black" linkRef="/careers">Close & Apply</PageButton>
            </div>
        </div>
    )
}

export const query = graphql`
    query JobBySlug($slug: String!){
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
              title
              role
              salary {
                amount
                visible
              }
              visible
              date
              requirements {
                details
                visible
              }
            }
          }
        }
  `

export default Job;